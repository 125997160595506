import React from "react";
import { graphql } from "gatsby";

import ReactMarkdown from "react-markdown";
import BackgroundImage from "gatsby-background-image";

import Layout from "../../components/Layout";

import styles from "./Page.module.scss";

export const query = graphql`
  query PageQuery($id: Int!) {
    strapiPage(strapiId: { eq: $id }) {
      strapiId
      title
      text
      seo_description
      image {
        publicURL
        childImageSharp {
          fluid(quality: 90, maxHeight: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`;

const Page = ({ data }) => {
  const page = data.strapiPage;
  const seoOptions = {
    title: page.title,
    description: page.seo_description || "",
    ogImage: page.image.publicURL,
  };

  return (
    <Layout fullscreenDesktop seoOptions={seoOptions}>
      <article className={styles.self}>
        <BackgroundImage
          className={styles.image}
          fluid={page.image.childImageSharp.fluid}
        />
        <div className={styles.text}>
          <ReactMarkdown source={page.text} />
        </div>
      </article>
    </Layout>
  );
};

export default Page;
